import {Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Grid, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import ErrorIcon from '@mui/icons-material/Error';


export default function ProjectPage() {

    const simsDesc = 'A scalable inventory management system that is fully customizable. ' +
        'It provides a solution for order and inventory tracking and management, real-time data visualizations, and a point of sale system.';

    const synopsiText = 'Synopsi is a text summarizer. Simply paste some text in the input field, click summarize, ' +
        'and then see an output of the summary.';

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} sx={{minHeight: '100vh', width: 1, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: 'url(/a5big.jpg)', backgroundSize: "cover"}}>
            <Grid item xs={12}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} sm={8} lg={4} sx={{justifyContent: 'center', alignItems: 'center'}}>
                <Grid container justifyContent={'center'} alignItems={'center'} sx={{borderRadius: '16px', backgroundColor: '#03161F', boxShadow: 10}}>
                    <Grid item xs={12} sx={{pr: 2, justifyContent: 'flex-end', alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                        <Button href={'/'} sx={{fontWeight: 'bold', color: '#0D77B0', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>Main</Button>
                        <Button href={'/about'} sx={{fontWeight: 'bold', color: '#0D77B0', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>About</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{mb: 5, mx: 5, p: 4, backgroundColor: '#03161F', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                            <Card sx={{maxWidth: 350, backgroundColor: '#D45313'}}>
                                <CardContent>
                                    <Typography sx={{textTransform: 'uppercase', fontWeight: 'bold', color: '#03161F', fontFamily: 'League Spartan, sans-serif', fontSize: 30}}>SIMS</Typography>
                                    <Typography sx={{textTransform: 'uppercase', fontWeight: 'bold', color: '#03161F', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>{simsDesc}</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button href={'/sims'} sx={{fontWeight: 'bold', color: '#03161F', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>More Info</Button>
                                </CardActions>
                            </Card>
                            <Card sx={{mt: 5, maxWidth: 350, backgroundColor: '#D45313'}}>
                                <CardContent>
                                    <Typography sx={{textTransform: 'uppercase', fontWeight: 'bold', color: '#03161F', fontFamily: 'League Spartan, sans-serif', fontSize: 30}}>Synopsi</Typography>
                                    <Typography sx={{textTransform: 'uppercase', fontWeight: 'bold', color: '#03161F', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>{synopsiText}</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button href={'/synopsi'} sx={{fontWeight: 'bold', color: '#03161F', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>More Info</Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12}></Grid>
        </Grid>

    )
};
