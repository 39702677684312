import {Route} from "react-router-dom";
import {Routes} from "react-router-dom";
import {BrowserRouter} from "react-router-dom";

import MainPage from "./components/MainPage";
import ProjectPage from "./components/ProjectPage";
import NewAboutPage from "./components/NewAboutPage";
import SimsPage from "./components/SimsPage";
import SynopsiPage from "./components/SynopsiPage";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route exact path={'/'} element={<MainPage/>}/>
                <Route exact path={'*'} element={<MainPage/>}/>
                <Route exact path={'/projects'} element={<ProjectPage/>}/>
                <Route exact path={'/about'} element={<NewAboutPage/>}/>
                <Route exact path={'/sims'} element={<SimsPage/>}/>
                <Route exact path={'/synopsi'} element={<SynopsiPage/>}/>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
