import {Avatar, Box, Button, Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";


export default function NewAboutPage(){

    const backgroundText = "My professional career started back in 2009 in the oil and\n" +
        "gas industry while I was at the University of Tulsa pursuing\n" +
        "an Energy Management degree. I continued on that path for 10 years until,\n" +
        "ultimately, industry trends compelled me to re-evaluate my future and my long\n" +
        "term goals. I eventually decided that my best bet would be to go back to school so,\n" +
        "in 2019 I got enrolled at National University and began my pursuit of a B.S. in Computer Science which was completed in 2023."

    const webText = "JavaScript | HTML | CSS | React | Material UI | Bootstrap | Express | Node | MongoDB | MongoDB Atlas | Realm | Python | Flask";

    const dataText = "Python | Pandas | NumPy | Matplotlib | seaborn | scikit-learn | Transformers | NLTK | IPython | Jupyter Notebook | JupyterLab | Spyder | Colab"

    const extraText = "sql | c++ | c# | R | Java | Sqlite | MySQL | Microsoft SQL Server | Git | GitHub | Firebase | Firestore | Cloud Functions | Google App Engine | Atlas App Services"

    return(
        <Grid container alignItems={'center'} justifyContent={'center'} sx={{display: 'flex', py: 5, minHeight: '100vh', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: 'url(/a5big.jpg)', backgroundSize: "cover"}}>
            <Grid item xs={12}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} sm={8} lg={6} sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <Grid container justifyContent={'center'} alignItems={'center'} sx={{borderRadius: '16px', backgroundColor: '#03161F', boxShadow: 10}}>
                    <Grid item xs={12} sx={{pr: 2, justifyContent: 'flex-end', alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                        <Button href={'/'} sx={{fontWeight: 'bold', color: '#0D77B0', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>Main</Button>
                        <Button href={'/projects'} sx={{fontWeight: 'bold', color: '#0D77B0', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>Projects</Button>
                    </Grid>
                    <Grid item xs={12} sx={{justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{mb: 2, mt: 5, mx: 5, backgroundColor: '#03161F', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography sx={{textTransform: 'uppercase', fontSize: 24, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#D45313'}}>Background</Typography>
                            <Typography sx={{textTransform: 'uppercase', fontSize: 16, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#0D77B0'}}>{backgroundText}</Typography>
                        </Box>
                        <Box sx={{mb: 5, mx: 5, backgroundColor: '#03161F', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography sx={{textTransform: 'uppercase', fontSize: 24, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#D45313'}}>Skills</Typography>
                            <Box sx={{p: 2, mb:2, mx: 2, backgroundColor: '#D45313', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography sx={{mb: 1, textTransform: 'uppercase', fontSize: 18, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#03161F'}}>Web Development</Typography>
                                <Typography wrap={'wrap'} sx={{ textTransform: 'uppercase', fontSize: 14, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#03161F'}}>{webText}</Typography>
                            </Box>
                            <Box sx={{p: 2, mx: 2, mb: 2, backgroundColor: '#D45313', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography sx={{mb: 1, textTransform: 'uppercase', fontSize: 18, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#03161F'}}>Data Tools</Typography>
                                <Typography wrap={'wrap'}sx={{textTransform: 'uppercase', fontSize: 14, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#03161F'}}>{dataText}</Typography>
                            </Box>
                            <Box sx={{p: 2, mx: 2, mb: 2, backgroundColor: '#D45313', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography sx={{mb: 1, textTransform: 'uppercase', fontSize: 18, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#03161F'}}>Additional Technologies</Typography>
                                <Typography wrap={'wrap'}sx={{textTransform: 'uppercase', fontSize: 14, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#03161F'}}>{extraText}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12}></Grid>
        </Grid>
    )
}