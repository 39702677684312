import {Avatar, Box, Button, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
export default function MainPage(){

    return(

        <Grid container alignItems={'center'} justifyContent={'center'} sx={{minHeight: '100vh', width: 1, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: 'url(/a5big.jpg)', backgroundSize: "cover"}}>
            <Grid item xs={12}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} sm={8} lg={4} sx={{justifyContent: 'center', alignItems: 'center'}}>
                <Grid container justifyContent={'center'} alignItems={'center'} sx={{borderRadius: '16px', backgroundColor: '#03161F', boxShadow: 10}}>
                    <Grid item xs={12} sx={{pr: 2, justifyContent: 'flex-end', alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                        <Button href={'/about'} sx={{fontWeight: 'bold', color: '#0D77B0', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>About</Button>
                        <Button href={'/projects'}sx={{fontWeight: 'bold', color: '#0D77B0', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>Projects</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{mb: 5, mx: 5, p: 4, backgroundColor: '#03161F', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                            <Avatar sx={{height: 200, width: 200, border: 'solid', borderColor: '#522014', borderWidth: 3}} src={"jack3.jpg"}></Avatar>
                            <Typography sx={{textTransform: 'uppercase', fontSize: 36, fontFamily: 'League Spartan, sans-serif', fontWeight: 'bold', mt: 1, color: '#D45313'}}>Jack Brower</Typography>
                            <Typography sx={{textTransform: 'uppercase', fontSize: 24, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#0D77B0'}}>Software Developer</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12}></Grid>
    </Grid>
    )
}