import {Avatar, Box, Button, Grid, ImageList, ImageListItem} from "@mui/material";
import Typography from "@mui/material/Typography";


export default function SynopsiPage(){

    const simsTech = 'Flask | Python | Transformers BART model | JavaScript | HTML | CSS | React | Material UI';

    const exampleText = 'Below is two examples of how Synopsi summarizes text. The first screenshot shows a summarized version of an AP News article. ' +
        'The second shows the summarization of a fictitious product review. These particular runs are using the DistilBART-cnn-12-6 Bart model. ' +
        'As can be seen in the Bart docs, Bart models use a standard sequence to sequence/machine translation architecture with a bidirectional encoder and a left-to-right decoder.';

    const itemData = [
        {
            img: 'synopsiNews.png',
            title: 'News Summary'
        },
        {
            img: 'synopsiReview.png',
            title: 'Review Summary'
        }
    ];

    return(
        <Grid container alignItems={'center'} justifyContent={'center'} sx={{minHeight: '100vh', width: 1, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: 'url(/a5big.jpg)', backgroundSize: "cover"}}>
            <Grid item xs={12}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} sm={8} lg={6} sx={{p: 3, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                <Grid container justifyContent={'center'} alignItems={'center'} sx={{display: 'flex', borderRadius: '16px', backgroundColor: '#03161F', boxShadow: 10}}>
                    <Grid item xs={12} sx={{pr: 2, justifyContent: 'flex-end', alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                        <Button href={'/about'} sx={{fontWeight: 'bold', color: '#0D77B0', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>About</Button>
                        <Button href={'/projects'}sx={{fontWeight: 'bold', color: '#0D77B0', fontFamily: 'League Spartan, sans-serif', fontSize: 16}}>Projects</Button>
                    </Grid>
                    <Grid item xs={12} sx={{justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{mb: 2, mt: 5, mx: 5, backgroundColor: '#03161F', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography sx={{textTransform: 'uppercase', fontSize: 24, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#D45313'}}>Technologies used in Synopsi</Typography>
                            <Typography sx={{textTransform: 'uppercase', fontSize: 16, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#0D77B0'}}>{simsTech}</Typography>
                        </Box>
                        <Box sx={{mb: 2, mt: 5, mx: 5, backgroundColor: '#03161F', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography sx={{textTransform: 'uppercase', fontSize: 24, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#D45313'}}>Example</Typography>
                            <Typography sx={{textTransform: 'uppercase', fontSize: 16, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#0D77B0'}}>{exampleText}</Typography>
                        </Box>
                        <Box sx={{mb: 2, mt: 5, mx: 5, backgroundColor: '#03161F', display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography sx={{textTransform: 'uppercase', fontSize: 24, fontWeight: 'bold', fontFamily: 'League Spartan, sans-serif', color: '#D45313'}}>Screenshots</Typography>
                            <ImageList sx={{ width: 7/8, height: 450 }} cols={1} rowHeight={350}>
                                {itemData.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <img
                                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                            alt={item.title}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12}></Grid>
        </Grid>
    )
}